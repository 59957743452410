import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { mq } from "../../styles/GlobalStyles";
import iconExternal from "../../images/icon-external--gray.svg";

interface InterviewsYamlData {
  id: string;
  site: {
    members: string[];
    title: string;
    url: string;
    hero: {
      childImageSharp: {
        gatsbyImageData: object;
      } | null;
    } | null;
    heroAlt: string | null;
  };
}

interface IndexData {
  edges: [
    {
      node: InterviewsYamlData;
    }
  ];
}

const Lists = styled.ul(
  mq({
    width: "100%",
    height: "100%",
    marginBottom: ["32px", "48px"],
    display: "flex",
    flexDirection: "column",
    gap: ["16px", "32px"],
  })
);

const List = styled.li(
  mq({
    transition: "all .3s",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "all .3s",
    },
    a: {
      display: "flex",
      textDecoration: "none",
      ".gatsby-image-wrapper": {
        display: "inline-block",
        width: ["90px", "300px"],
        height: ["90px", "190px"],
        img: {
          clipPath: "inherit",
          width: ["90px", "300px"],
          borderRadius: ["12px", "16px"],
        },
      },
    },
  })
);

const ListDescription = styled.span(
  mq({
    padding: ["0 0 0 16px", "0 0 0 24px"],
    width: ["calc(100% - 90px)", "calc(100% - 300px)"],
  })
);

const Title = styled.span(
  mq({
    display: "block",
    fontSize: ["0.9em", "1.2em"],
    fontWeight: "bold",
    lineHeight: "1.7",
    marginBottom: "8px",
    "&:after": {
      content: `""`,
      display: "inline-block",
      paddingRight: "25px",
      width: "25px",
      height: "14px",
      backgroundImage: `url(${iconExternal})`,
      backgroundPosition: "5px top",
      backgroundSize: "14px 14px",
      backgroundRepeat: "no-repeat",
      marginLeft: "4px",
    },
  })
);

const Members = styled.span(
  mq({
    fontSize: "0.8em",
    color: "#999999",
    fontWeight: "bold",
    display: ["none", "block"],
  })
);

/* MAX_LENGTHまでで文字数制限をしてハミ出た部分は"..."表示 */
const ellipsisTexts = (excerpt: string, max: number): string => {
  if (!excerpt) return "";

  if (excerpt.length > max) {
    return `${excerpt.substring(0, max)}...`;
  } else {
    return excerpt;
  }
};

export const InterviewList = ({
  lists,
}: {
  lists: IndexData[];
}): JSX.Element => (
  <Lists>
    {lists.map(({ node }: { node: InterviewsYamlData }) => (
      <List key={node.id}>
        <a href={`${node.site.url}`} target="_blank" rel="noreferrer">
          {node.site.hero ? (
            <GatsbyImage
              image={node.site.hero?.childImageSharp?.gatsbyImageData}
              alt={node.site?.heroAlt}
              as="span"
            />
          ) : null}
          <ListDescription>
            <Title>{ellipsisTexts(node.site.title, 80)}</Title>
            <Members>{node.site.members.join(" / ")}</Members>
          </ListDescription>
        </a>
      </List>
    ))}
  </Lists>
);

export default InterviewList;
