import styled from "@emotion/styled";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton,
} from "react-share";

interface Props {
  title: string;
  url: string;
}
const Root = styled.div({
  marginTop: "3.5em",
  textAlign: "center",
});

const SNSLinks = styled.div({
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "flex-end",
  marginTop: "10px",
});
const SNSIcon = styled.div({
  marginRight: "1em",
});

export const Share = (props: Props) => {
  const articleTitle = props.title;
  const articleUrl = props.url;
  const iconSize = 35;

  return (
    <Root>
      <SNSLinks>
        <SNSIcon>
          <TwitterShareButton url={articleUrl} title={articleTitle}>
            <TwitterIcon round size={iconSize} />
          </TwitterShareButton>
        </SNSIcon>
        <SNSIcon>
          <FacebookShareButton url={articleUrl}>
            <FacebookIcon round size={iconSize} />
          </FacebookShareButton>
        </SNSIcon>
        <SNSIcon>
          <LineShareButton url={articleUrl} title={articleTitle}>
            <LineIcon round size={iconSize} />
          </LineShareButton>
        </SNSIcon>
        <SNSIcon>
          <HatenaShareButton url={articleUrl} title={articleTitle}>
            <HatenaIcon round size={iconSize} />
          </HatenaShareButton>
        </SNSIcon>
      </SNSLinks>
    </Root>
  );
};

export default Share;
