/* eslint-disable @typescript-eslint/no-misused-promises */
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import Pagination from "@mui/material/Pagination";
import { navigate } from "gatsby";
import React from "react";
import { mq } from "../../styles/GlobalStyles";

interface PageInfo {
  currentPage: number;
  hasNextPage: boolean;
  itemCount: number;
  hasPreviousPage: boolean;
  pageCount: number;
  perPage: number;
  totalCount: number;
}

const Container = styled.div(
  mq({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    width: "100%",
  })
);

const ListsStyle = css(
  mq({
    ".MuiPagination-ul": {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "center",
      width: ["100%", "inherit"],
      height: "30px",
      gap: "8px",
      "> li": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "> button": {
          fontWeight: "bold",
          fontSize: "0.95em",
          fontFamily: `"Manrope", sans-serif`,
          color: "#2E3C4D",
          display: "inline-block",
          textDecoration: "none",
          margin: ["0 auto"],
          textAlign: "center",
          lineHeight: ["25px", "30px"],
          width: ["25px", "30px"],
          minWidth: ["25px", "30px"],
          height: ["25px", "30px"],
          transition: "all .3s",
          padding: "0",
          "&:hover": {
            transform: "scale(1.2)",
            transition: "all .3s",
          },
          "&.Mui-selected": {
            backgroundColor: "#E60012",
            lineHeight: ["30px", "35px"],
            borderRadius: "45px",
            width: ["30px", "35px"],
            minWidth: ["30px", "35px"],
            height: ["30px", "35px"],
            color: "#FFFFFF",
          },
        },
        "> div": {
          minWidth: "15px",
          padding: "0",
        },
      },
    },
  })
);
/* https://mui.com/components/pagination/ */
export function Pager({ pageInfo }: { pageInfo: PageInfo }) {
  const onChangePage = async (
    event: React.ChangeEvent<unknown>,
    value: number
  ) => {
    await navigate(`/entries/page/${value}`);
  };
  return (
    <Container>
      <Pagination
        css={ListsStyle}
        count={pageInfo.pageCount}
        page={pageInfo.currentPage}
        size="large"
        siblingCount={1}
        onChange={onChangePage}
      />
    </Container>
  );
}
