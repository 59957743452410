import { css } from "@emotion/react";
import emotionNormalize from "emotion-normalize";
import facepaint from "facepaint";

const GlobalStyles = css`
  ${emotionNormalize}

  *, *:before, *:after {
    box-sizing: border-box;
  }

  html {
    font-size: 62.5%;
  }

  body {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-text-size-adjust: none;
    text-size-adjust: none;
  }

  html,
  body,
  div,
  span,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  address,
  code,
  em,
  img,
  q,
  strong,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  form,
  label,
  fieldset,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  button,
  figure {
    padding: 0;
    margin: 0;
    font-style: normal;
    border: none;
    background: none;
  }

  section,
  nav,
  article,
  aside,
  header,
  footer,
  picture {
    display: block;
  }

  blockquote,
  q {
    quotes: none;
  }

  a,
  img,
  fieldset {
    border: 0;
  }

  img {
    max-width: 100%;
    height: auto;
    vertical-align: top;
  }

  ul,
  ol {
    list-style: none;
  }

  li {
    list-style-type: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .text-left {
    text-align: left !important;
  }

  .text-right {
    text-align: right !important;
  }

  .text-center {
    text-align: center !important;
  }

  body {
    background-color: #faf9f9;
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN",
      "Hiragino Sans", Meiryo, sans-serif;
    font-size: 1.6rem;
    line-height: 1.875;
    color: #000;
  }
  strong,
  .bold {
    font-weight: 700;
  }

  .en {
    font-family: "Manrope", sans-serif;
    font-weight: 600;
    font-style: normal;
  }

  .en.bold {
    font-weight: 800;
  }

  a {
    color: #2e3c4d;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
  }
`;

const baseUnit = {
  emBase: 16, //em
  maxWidth: 2000, //px
  pcBreakPoint: 940, //px
  tabletBreakPoint: 939, //px
  spBreakPoint: 768, //px
};

const breakpoints: number[] = [
  baseUnit.spBreakPoint / baseUnit.emBase,
  baseUnit.tabletBreakPoint / baseUnit.emBase,
  baseUnit.pcBreakPoint / baseUnit.emBase,
];

// { property: ["sp", "tablet", "pc"] } でMediaQueryを取り扱う
const mq = facepaint(breakpoints.map((bp) => `@media(min-width: ${bp}em)`));

export { GlobalStyles, mq };
