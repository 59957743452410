import styled from "@emotion/styled";
import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { mq } from "../../styles/GlobalStyles";

interface SlidesYamlData {
  id: string;
  site: {
    members: string[];
    title: string;
    url: string;
    hero: {
      childImageSharp: {
        gatsbyImageData: object;
      } | null;
    } | null;
    heroAlt: string | null;
  };
}

interface IndexData {
  edges: [
    {
      node: SlidesYamlData;
    }
  ];
}

const Lists = styled.ul(
  mq({
    width: "100%",
    height: "100%",
    display: ["flex", "grid"],
    flexDirection: "column",
    gridGap: "24px 24px",
    gridTemplateColumns: "49% 49%",
    marginBottom: ["32px", "48px"],
  })
);

const List = styled.li(
  mq({
    maxWidth: "518px",
    maxHeight: "260px",
    display: "block",
    position: "relative",
    overflow: "hidden",
    boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.1)",
    borderRadius: ["12px", "16px"],
    transition: "all .3s",
    "&:hover": {
      transform: "scale(1.02)",
      transition: "all .3s",
      boxShadow: "0px 24px 40px rgba(0, 0, 0, 0.2)",
    },
    a: {
      display: "inline-block",
      textDecoration: "none",
      width: "100%",
      height: "100%",
      aspectRatio: ["3 / 2", "initial"],
      ".gatsby-image-wrapper": {
        display: "inline-block",
        width: "100%",
        height: "100%",
        img: {
          clipPath: "inherit",
          objectFit: "cover",
          borderRadius: ["12px", "16px"],
        },
      },
    },
  })
);

const ListDescription = styled.span(
  mq({
    display: "block",
    padding: "16px",
    position: "absolute",
    bottom: "0",
    backgroundColor: "rgba(0,0,0,0.65)",
    borderRadius: ["0 0 12px 12px", "0 0 16px 16px"],
    width: "100%",
  })
);

const Title = styled.span(
  mq({
    display: "block",
    fontSize: ["0.8em", "0.9em"],
    fontWeight: "bold",
    lineHeight: ["1.2", "1.7"],
    color: "#FFFFFF",
  })
);

/* MAX_LENGTHまでで文字数制限をしてハミ出た部分は"..."表示 */
const ellipsisTexts = (excerpt: string, max: number): string => {
  if (!excerpt) return "";

  if (excerpt.length > max) {
    return `${excerpt.substring(0, max)}...`;
  } else {
    return excerpt;
  }
};

export const SlideList = ({ lists }: { lists: IndexData[] }): JSX.Element => (
  <Lists>
    {lists.map(({ node }: { node: SlidesYamlData }) => (
      <List key={node.id}>
        <a href={`${node.site.url}`} target="_blank" rel="noreferrer">
          {node.site.hero ? (
            <GatsbyImage
              image={node.site.hero?.childImageSharp?.gatsbyImageData}
              alt={node.site?.heroAlt}
              as="span"
            />
          ) : null}
          <ListDescription>
            <Title>{ellipsisTexts(node.site.title, 60)}</Title>
          </ListDescription>
        </a>
      </List>
    ))}
  </Lists>
);

export default SlideList;
