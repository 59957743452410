import * as React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import ogp_image from "../../static/images/OGP.png";

interface SeoQuery {
  siteMetadata: {
    defaultTitle: string;
    defaultDescription: string;
    siteUrl: string;
    twitterUsername: string;
    titleTemplate: string;
  };
}
type ogp_image = string;

export const Seo = ({
  description,
  lang = "ja",
  meta,
  title,
  image,
  url,
}: {
  description: string;
  lang: string;
  meta: object[];
  title: string;
  image: string;
  url: string;
}): React.ReactElement => {
  const { site }: { site: SeoQuery } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl
            twitterUsername
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata?.defaultDescription;
  const titleTemplate = site.siteMetadata?.titleTemplate;
  const siteUrl = site.siteMetadata?.siteUrl;
  const twitterUsername = site.siteMetadata?.twitterUsername;
  const defaultOgImg = `${siteUrl}${ogp_image}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={titleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: `${title} | ${site.siteMetadata?.defaultTitle}`,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:url`,
          content: `${siteUrl}/${url ? url : ""}`,
        },
        {
          property: `og:image`,
          content: image || defaultOgImg,
        },
        {
          property: `og:type`,
          content: `blog`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: `${title} | ${site.siteMetadata?.defaultTitle}`,
        },
        {
          name: `twitter:image`,
          content: image || defaultOgImg,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "twitter:creator",
          content: twitterUsername,
        },
      ].concat(meta)}
    >
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=G-JBTT649LXB"
      ></script>
      <script>
        {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-JBTT649LXB');
        `}
      </script>
      <link
        rel="alternate"
        type="application/rss+xml"
        title="RSS"
        href="/rss.xml"
      />
    </Helmet>
  );
};

Seo.defaultProps = {
  lang: `ja`,
  meta: [],
  description: ``,
  image: null,
  url: null, //slugを渡す
};

export default Seo;
