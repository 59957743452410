import React from "react";
import styled from "@emotion/styled";
import { mq } from "../../styles/GlobalStyles";
import iconExternal from "../../images/icon-external.svg";
import { Link } from "gatsby";

const Lists = styled.ul(
  mq({
    display: "flex",
    flexWrap: "wrap",
    marginBottom: "24px",
  })
);

const List = styled.li(
  mq({
    width: ["100%", "100%", "33.33%"],
    paddingRight: "30px",
    paddingLeft: "12px",
    a: {
      textDecoration: "none",
      fontSize: ["0.8em", "1.4rem"],
      lineHeight: "1.7142857143",
      color: "#dddddd",
      "&:hover": {
        textDecoration: "underline",
      },
      "&:after": {
        content: `""`,
        display: "inline-block",
        paddingRight: "25px",
        width: "25px",
        height: "14px",
        backgroundImage: `url(${iconExternal})`,
        backgroundPosition: "5px top",
        backgroundSize: "14px 14px",
        backgroundRepeat: "no-repeat",
      },
    },
  })
);

const MenuList = styled.li(
  mq({
    paddingRight: "32px",
    paddingLeft: "12px",
    width: ["100%", "100%", "14%"],
    a: {
      textDecoration: "none",
      fontSize: ["0.9em", "1.5rem"],
      lineHeight: "1.8",
      color: "#dddddd",
      "&:hover": {
        textDecoration: "underline",
      },
    },
  })
);

const P = styled.p(
  mq({
    fontSize: "1em",
    fontWeight: "bold",
    color: "#FFFFFF",
    marginBottom: ["8px", "4px"],
    fontFamily: `"Manrope", sans-serif`,
  })
);

const Root = styled.nav(mq({ marginright: ["0", "-15px"] }));

export const SubNav = () => (
  <Root>
    <P>− Developer Portal</P>
    <Lists>
      <MenuList>
        <Link to="/entries">Tech Blog</Link>
      </MenuList>
      <MenuList>
        <Link to="/interviews">Interviews</Link>
      </MenuList>
      <MenuList>
        <a
          href="https://speakerdeck.com/medley"
          target="_blank"
          rel="noreferrer"
        >
          Slides
        </a>
      </MenuList>
      <MenuList>
        <a href="https://www.medley.jp/jobs" target="_blank" rel="noreferrer">
          Recruit
        </a>
      </MenuList>
    </Lists>
    <P>− Services</P>
    <Lists>
      <List>
        <a href="https://clinics-cloud.com/" target="_blank" rel="noreferrer">
          クラウド診療支援システム「CLINICS」
        </a>
      </List>
      <List>
        <a href="https://pharms-cloud.com/" target="_blank" rel="noreferrer">
          かかりつけ薬局支援システム「Pharms」
        </a>
      </List>
      <List>
        <a href="https://dentis-cloud.com/" target="_blank" rel="noreferrer">
          クラウド歯科業務支援システム「Dentis」
        </a>
      </List>
      <List>
        <a href="https://medley.life/" target="_blank" rel="noreferrer">
          オンライン医療事典「MEDLEY」
        </a>
      </List>
      <List>
        <a href="https://job-medley.com/" target="_blank" rel="noreferrer">
          医療介護の求人サイト「ジョブメドレー」
        </a>
      </List>
      <List>
        <a
          href="https://www.kaigonohonne.com/"
          target="_blank"
          rel="noreferrer"
        >
          納得できる老人ホーム選び「介護のほんね」
        </a>
      </List>
    </Lists>
  </Root>
);

export default SubNav;
