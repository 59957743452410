import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mq } from "../../styles/GlobalStyles";
//eslint-disable-next-line import/no-unresolved
import { useLocation } from "@reach/router";

const Root = styled.nav(
  mq({
    display: "flex",
    flex: "1",
    justifyContent: ["center", "center", "flex-end"],
    width: ["100%", "unset"],
    fontFamily: `"Manrope", sans-serif`,
    fontWeight: "600",
    fontSize: ["1.4rem", "1.8rem"],
    marginRight: ["0", "-15px"],
    marginTop: ["4px", "16px", "0"],
    lineHeight: "1",
    letterSpacing: "0",
  })
);

const Lists = styled.ul(
  mq({
    display: "flex",
    flexWrap: "no-wrap",
    flexShrink: "1",
    gap: ["20px", "32px"],
  })
);

const List = styled.li({
  a: {
    position: "relative",
    display: "inline-block",
    padding: "15px 0",
    textDecoration: "none",
    "&:after": {
      position: "absolute",
      content: `""`,
      display: "block",
      bottom: "-4px",
      left: "0",
      height: "3px",
      backgroundColor: "#E60012",
      width: "100%",
      transform: "scale(0, 1)",
      transformOrigin: "center top",
      transition: "all .3s",
    },
    "&:hover::after": {
      transform: "scale(1, 1)",
    },
  },
});

function isActiveUrl(path: string): boolean {
  //eslint-disable-next-line
  const location = useLocation();
  const documentUrl: string = location.pathname;
  return documentUrl.match(path) !== null;
}

export const Nav = () => (
  <Root>
    <Lists>
      <List
        className={
          isActiveUrl("/entries") || isActiveUrl("/entry")
            ? `is-active`
            : undefined
        }
      >
        <Link to="/entries">Tech Blog</Link>
      </List>
      <List className={isActiveUrl("/interviews") ? `is-active` : undefined}>
        <Link to="/interviews">Interviews</Link>
      </List>
      <List>
        <a
          href="https://speakerdeck.com/medley"
          target="_blank"
          rel="noreferrer"
        >
          Slides
        </a>
      </List>

      <List>
        <a href="https://www.medley.jp/jobs" target="_blank" rel="noreferrer">
          Recruit
        </a>
      </List>
    </Lists>
  </Root>
);

export default Nav;
