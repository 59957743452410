import styled from "@emotion/styled";
import { Link } from "gatsby";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import React from "react";
import { mq } from "../../styles/GlobalStyles";

interface MarkdownRemarkData {
  id: string;
  excerpt: string;
  frontmatter: {
    slug: string;
    date: Date;
    title: string;
    featuredImgAlt: string;
    hero: {
      childImageSharp: {
        gatsbyImageData: IGatsbyImageData;
      };
    };
    heroAlt: string;
  };
  featuredImg?: {
    childImageSharp: {
      gatsbyImageData: IGatsbyImageData;
    };
  };
}

interface IndexData {
  node: MarkdownRemarkData;
}

const Lists = styled.ul(
  mq({
    display: "flex",
    width: "100%",
    height: "100%",
    flexDirection: ["column", "row"],
    flexWrap: "wrap",
    justifyContent: ["center", "space-between"],
    gap: "24px",
    marginBottom: ["32px", "48px"],
  })
);

const List = styled.li(
  mq({
    display: "block",
    maxWidth: ["inherit", "inherit", "337px"],
    width: ["100%", "calc(50% - 12px)", "calc(calc(100% - 48px) / 3)"],
    backgroundColor: "#FFFFFF",
    overflow: "hidden",
    boxShadow: "0px 0px 32px rgba(0, 0, 0, 0.03)",
    borderRadius: ["12px", "16px"],
    transition: "all .3s",
    "&:hover": {
      transform: "scale(1.05)",
      transition: "all .3s",
      boxShadow: "0px 24px 40px rgba(0, 0, 0, 0.1)",
    },
    ".feature&:first-of-type": {
      maxWidth: "100%",
      width: "100%",
      height: ["auto", "260px"],
      a: {
        display: "flex",
        flexDirection: ["column", "row"],
        position: "relative",
        width: "100%",
        height: "100%",
        "&:before": {
          display: "inline-block",
          content: `"NEW"`,
          fontFamily: `"Manrope", sans-serif`,
          color: "#E60012",
          backgroundColor: "#FAF9F9",
          padding: "0 12px",
          borderRadius: "16px",
          fontSize: ["0.8em", "0.9em"],
          position: "absolute",
          left: "16px",
          top: "16px",
          zIndex: "1",
        },
        ".gatsby-image-wrapper": {
          display: "inline-block",
          /* TODO: fix width  */
          width: ["inherit", "442px"],
          marginRight: ["0", "12px"],
          img: {
            clipPath: "inherit",
            height: "100%",
            width: "100%",
            objectFit: ["cover", "contain !important"],
            objectPosition: "50% 50%",
            borderRadius: ["12px 12px 0 0", "16px 0 0 16px"],
          },
        },
        h3: {
          fontSize: ["1em", "1.7em"],
        },
        p: {
          display: "block",
        },
      },
    },
    a: {
      display: "inline-block",
      width: "100%",
      textDecoration: "none",
      ".gatsby-image-wrapper": {
        display: "inline-block",
        width: "100%",
        aspectRatio: "1.90 / 1",
        img: {
          clipPath: "inherit",
          height: "100%",
          width: "100%",
          objectFit: "cover",
          borderRadius: ["12px 12px 0 0", "16px 16px 0px 0px"],
        },
      },
    },
    p: {
      display: "none",
    },
  })
);

const ListDescription = styled.span(
  mq({
    display: "block",
    padding: ["16px 24px", "24px"],
  })
);

const Date = styled.span({
  fontSize: "0.9em",
  fontWeight: "bold",
  color: "#999999",
  display: "block",
  marginBottom: "4px",
  fontFamily: `"Manrope", sans-serif`,
});

const Title = styled.h3(
  mq({
    display: "block",
    fontSize: ["1em", "1.2em"],
    fontWeight: "bold",
    color: "#2E3C4D",
    lineHeight: "1.7",
    marginBottom: "8px",
  })
);

const Excerpt = styled.p(
  mq({
    display: ["none", "block"],
    fontSize: ["0.8em", "0.9em"],
    lineHeight: "1.8",
  })
);

/* MAX_LENGTHまでで文字数制限をしてハミ出た部分は"..."表示 */
const ellipsisTexts = (excerpt: string, max: number): string => {
  if (!excerpt) return "";

  if (excerpt.length > max) {
    return `${excerpt.substring(0, max)}...`;
  } else {
    return excerpt;
  }
};

export const BlogList = ({
  lists,
  feature,
}: {
  lists: IndexData[];
  feature?: boolean;
}) => (
  <Lists>
    {lists.map(({ node }: { node: MarkdownRemarkData }) => (
      <List key={node.id} className={feature ? "feature" : undefined}>
        {/* Topのような見た目のリストの場合はfeature={true}を渡しておくとリストのclassにfeatureを付与 */}
        <Link to={`/${node.frontmatter.slug}`}>
          {node.featuredImg ? (
            <GatsbyImage
              image={node.featuredImg.childImageSharp.gatsbyImageData}
              alt={node.frontmatter.featuredImgAlt}
              objectFit="cover"
              objectPosition="center"
              as="span"
            />
          ) : (
            <GatsbyImage
              image={node.frontmatter.hero.childImageSharp.gatsbyImageData}
              alt={node.frontmatter.heroAlt}
              objectFit="cover"
              objectPosition="center"
              as="span"
            />
          )}
          <ListDescription>
            <Date>{node.frontmatter.date}</Date>
            <Title>{ellipsisTexts(node.frontmatter.title, 60)}</Title>
            <Excerpt>{ellipsisTexts(node.excerpt, 35)}</Excerpt>
          </ListDescription>
        </Link>
      </List>
    ))}
  </Lists>
);

export default BlogList;
