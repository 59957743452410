import React from "react";
import { Global } from "@emotion/react";
import styled from "@emotion/styled";
import { Header, Footer } from "../../components";
import { GlobalStyles, mq } from "../../styles/GlobalStyles";
import pcBgImage from "../../images/d-bg-decoration.png";
import spBgImage from "../../images/d-bg-decoration@2x.png";

const Root = styled.div(
  mq({
    position: "relative",
    margin: "0 auto",
    overflow: "hidden",
    backgroundColor: "#FAF9F9",
    backgroundPosition: "center top",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
    backgroundImage: [`url(${spBgImage})`, `url(${pcBgImage})`],
  })
);

const Main = styled.main(
  mq({
    position: "relative",
    overflow: "hidden",
    paddingBottom: ["40px", "80px"],
    textAlign: "left",
  })
);

const Container = styled.div(
  mq({
    position: "relative",
    top: "0",
    width: "100%",
    maxWidth: ["unset", "1160px"],
    margin: "0 auto",
    padding: ["0 24px", "0 50px"],
    textAlign: "left",
  })
);

export const Layout = ({ children }: { children: React.ReactElement }) => {
  return (
    <Root>
      <Global styles={GlobalStyles} />
      <Header />
      <Main>
        <Container>{children}</Container>
      </Main>
      <Footer />
    </Root>
  );
};

export default Layout;
