import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { SubNav } from "../SubNav";
import { mq } from "../../styles/GlobalStyles";

const Root = styled.footer(
  mq({
    backgroundColor: "#2E3C4D",
    textAlign: "left",
  })
);

const Wrapper = styled.div(
  mq({
    padding: ["64px 0 32px", "90px 0 58px"],
    backgroundColor: "#2E3D4D",
  })
);

const Container = styled.div(
  mq({
    position: "relative",
    top: "0",
    width: "100%",
    maxWidth: "1160px",
    margin: "0 auto",
    padding: ["0 25px", "0 50px"],
    textAlign: "left",
  })
);

const Logo = styled.p(
  mq({
    marginBottom: ["30px", "40px"],
    fontFamily: `"Manrope", sans-serif`,
    fontWeight: "800",
    fontSize: ["1.5rem", "2.1rem"],
    lineHeight: "1",
    letterSpacing: "0",
    a: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
      color: "#F9F9FA",
    },
    img: {
      display: "inline-block",
      width: ["114px", "160px"],
      height: ["20px", "28px"],
      marginRight: ["10px", "15px"],
      verticalAlign: "top",
    },
  })
);

const Copyright = styled.p({
  textAlign: "right",
  backgroundColor: "#1F2936",
});

const Small = styled.small({
  fontFamily: `"Manrope", sans-serif`,
  fontSize: "1.2rem",
  lineHeight: "1",
  letterSpacing: "0",
  color: "#F9F9FA",
  textAlign: "right",
  display: "block",
  width: "100%",
  maxWidth: "1160px",
  margin: "0 auto",
  padding: ["12px 0", "16px"],
});

const imageWrapperStyle = css(
  mq({
    display: "inline-block",
    width: ["114px", "160px"],
    height: ["20px", "28px"],
    marginRight: ["10px", "15px"],
    verticalAlign: "top",
  })
);

export const Footer = () => (
  <Root>
    <Wrapper>
      <Container>
        <Logo>
          <Link to="/">
            <StaticImage
              src={`../../images/medley-logo.svg`}
              alt="株式会社メドレー"
              as="span"
              className={imageWrapperStyle}
            />
            Developer Portal
          </Link>
        </Logo>
        <SubNav />
      </Container>
    </Wrapper>
    <Copyright>
      <Small>&copy; 2016 MEDLEY, INC.</Small>
    </Copyright>
  </Root>
);

export default Footer;
