import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { mq } from "../../styles/GlobalStyles";
import { Nav } from "../Nav";

const Root = styled.header(
  mq({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
    width: "100%",
    maxWidth: "1600px",
    margin: "0 auto",
    flexDirection: ["column", "column", "row"],
    padding: ["32px 0 0", "40px 0 0", "50px 50px 32px"],
    p: {
      marginBottom: "0",
    },
  })
);

const P = styled.p(
  mq({
    margin: "0 auto",
    fontFamily: `"Manrope", sans-serif`,
    fontWeight: "800",
    lineHeight: "1",
    letterSpacing: "0",
    fontSize: ["1.7rem", "2.1rem"],
    textAlign: ["center", "left"],
    a: {
      display: "flex",
      alignItems: "center",
      textDecoration: "none",
    },
    img: {
      width: ["125px", "160px", "160px"],
      marginRight: ["12px", "15px", "15px"],
      verticalAlign: "top",
    },
  })
);

const imageWrapperStyle = css(
  mq({
    display: "block",
    width: ["125px", "160px", "160px"],
    marginRight: ["12px", "15px", "15px"],
    marginBottom: "10px",
    verticalAlign: "top",
  })
);

export const Header = () => (
  <Root>
    <P>
      <Link to="/">
        <StaticImage
          src={`../../images/medley-logo.svg`}
          alt="株式会社メドレー"
          as="span"
          className={imageWrapperStyle}
        />
        Developer Portal
      </Link>
    </P>

    <Nav />
  </Root>
);

export default Header;
